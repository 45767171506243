<template>
  <div>
    <!-- 软著列表 - 附件快传 -->
    <Header back="返回" title="附件快传" index="首页" titleOne="知识产权" titleTwo="软著列表" beforeTitle="附件快传" />
    <div class="content">
      <div class="ImportTop">
        <el-upload
          class="upload-demo"
          ref="imgupload"
          action="string"
          :http-request="httpRequest"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <el-button type="primary" class="ChooseFile">请上传附件</el-button>
        </el-upload>
        <!-- <el-button type="primary" class="ChooseFile" @click="dialogTableVisible = true">请上传附件</el-button> -->
        <span style="margin-left: 20px;color: #A6ABC7;">附件上传文件：只支持压缩包zip。</span>
      </div>
      <div>
        <h1>附件文件压缩格式说明</h1>
        <p>
          附件说明：按照文件夹（自定义文件夹名称即可）格式压缩文件，压缩包以“zip”格式进行压缩，文件类型（doc，word，pdf，excel，png，jpg），文件夹内的文件以“软著名称”或“软著编号命名”
          <span
            class="look_img"
            @click="lookImg()"
          >查看图片</span>
        </p>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="Loading···"
        :data="fileList"
        style="width: 100%;margin-top: 40px;"
      >
        <el-table-column prop="file_name" label="附件名称" :show-overflow-tooltip="true" width="700">
          <template slot-scope="scope">
            {{
            scope.row.file_name ? scope.row.file_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="上传时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.create_time ? scope.row.create_time : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="finish_time" label="完成时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.finish_time ? scope.row.finish_time : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="附件" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="!scope.row.status">未执行</span>
            <span style="color: #5cc038;" v-if="scope.row.status">已完成</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="lookTitle" :visible.sync="dialogVisible" width="50%">
      <div class="img_box">
        <img class="annex_img" :src="annexImg" alt="上传附件说明图" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import axios from 'axios'
// import { getPatentQuickList } from '../../utils/api.js'
export default {
  data () {
    return {
      fileList: [],
      annexImg: '',
      loading: false,//表格动画默认false
      dialogVisible: false,//弹出框
      lookTitle: '',//弹出框提示
      annexParams: {
        page: 1,
        limit: 10,
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.axios.get('/api/soft/soft_work_list', this.annexParams).then((res) => {
        this.fileList = res.data.data
      })
    },
    lookImg () {
      this.lookTitle = '附件说明'
      this.annexImg = require('../../assets/images/soft_upload.png')
      this.dialogVisible = true
    },
    httpRequest (param) {
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      this.upload("/api/soft/upload_multi_file", form).then((res) => {
        this.code = res.code;
        console.log(res);
        this.dialogFormVisible = false;
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.getList()
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
  },
}
  </script>
  
  <style scoped>
.ImportTop {
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
}
.ChooseFile {
  width: 370px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}
.look_img {
  cursor: pointer;
  color: #0f38ff;
  margin-left: 20px;
}
.dialog-footer {
  display: flex;
  justify-content: space-around;
}
div/deep/.el-dialog__title {
  font-weight: bold;
}
.img_box {
  padding: 78px 13px;
  border: 1px solid #edeef4;
  margin: 70px 0;
}
.annex_img {
  width: 100%;
}
.ImportTop {
  display: flex;
  align-items: center;
}
.status_ok {
  color: #5cc038;
}
</style>